import React from 'react';
import ValidationError from '@alj-react/ui-components/dist/components/Forms/sub-components/ValidationError/ValidationError';
import FieldDescription from '@alj-react/ui-components/dist/components/Forms/sub-components/FieldDescription/FieldDescription';
import { Field } from '@alj-react/efo';

import styled from 'styled-components';

/** -----------styled tag-------------------- */
const FieldWrap = styled.div`
  margin: 0 auto;
  text-align: center;
  display: block;
  width: 100%;
  max-width: 366px;
  text-align: center;
`;

const InputWrap = styled.div`
  margin: 0 auto;
  text-align: center;
  display: block;
  flex-direction: row;
  width: 100%;
  align-items: center;
  position: relative;
`;

type Props = {
  /** birthday field */
  field: Field<string>;
  /** ClassName to edit style of the component */
  className?: string;
  /** HTML id attribute, also passed to label for HTML for attribute */
  id?: string;
  /** Additional text rendered below the input for description/footnote */
  description?: string;
  /** Makes field disabled */
  isDisabled?: boolean;
  type?: string;
  style?: any;
};

const EfoBirthDayField = (props: Props) => {
  const { field, className, id, description, type = 'text', ...rest } = props;

  const fieldRef = React.useRef<HTMLInputElement>(null);
  const errorFlag: boolean =
    typeof field.showError !== 'undefined'
      ? field.hasError && field.showError
      : Boolean(field.errors[0]);
  const showError = field.errors.length > 0 && field.showError;
  const { isDisabled } = props;
  return (
    <FieldWrap>
      <div className={className || ''}>
        <InputWrap>
          <input
            id={field.name}
            name={field.name}
            ref={fieldRef}
            type={type}
            className={`TextField__input${errorFlag ? ' has-error' : ''}`}
            onChange={field.onChange}
            onBlur={field.onBlur}
            disabled={isDisabled}
            maxLength={8}
            value={field.value}
            size={8}
            {...rest}
          />
        </InputWrap>
        <ValidationError errorMessage={field.errors[0]} showError={showError} />
      </div>
      <FieldDescription>{description}</FieldDescription>
    </FieldWrap>
  );
};

EfoBirthDayField.defaultProps = {
  type: 'text',
};

export default EfoBirthDayField;
