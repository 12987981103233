const colors = {
  // Brand colors
  axaBlue: '#00008f',
  axaRed: '#ff1721',

  // Main colors
  burntSienna: '#f07662',
  darkGray: '#333333',
  mediumGray: '#5f5f5f',
  gray: '#7f7f7f',
  lightGray: '#999999',
  silver: '#cccccc',
  mercury: '#e5e5e5',
  wildSand: '#f5f5f5',
  alabaster: '#fafafa',
  white: '#ffffff',

  // Background
  oceanBlue: '#4976ba',
  teal: '#027180',
  pacific: '#00adc6',
  viridianGreen: '#668980',
  greyjoy: '#9fbeaf',
  azaela: '#e196aa',
  logan: '#9190ac',
  apache: '#bc9d45',
  tosca: '#914146',
  igloo: '#b5d0ee',
  cottonCandy: '#fad6de',
  aquaGreen: '#9fd9b4',
  acid: '#f0ff93',
  dune: '#fcd385',

  // status
  malachite: '#1cc54e',
  shyTomato: '#c91432',

  // effect
  blueHover: '#000072',
  burntSiennaHover: '#c05e4e',

  // myaxa / hp specific colors
  darkIndigo: '#3b3fd8',
  ceruleanBlue: '#3a3fd8',
  catskillWhite: '#e9ecf5',
  policyDetailsOrange: '#ec4d34',

  // page specific
  steelBlue: '#4975ba',
  stTropaz: '#29559a',

  // forms
  asteriskInfoBlue: '#0062a9',
  brightRed: '#f00',
};

export default colors;
