import React from 'react';
import useFormValidator, { ValidatorSetup } from '@alj-react/efo';
import BirthdayCheckForm from './BirthdayCheckForm';

import { inputBirthDayFormValidator } from '../../utils/characterValidator';

const BirthdayCheckPage = () => {
  const condRequired = {
    birthDay: (message: string) => (tempValue: string) =>
      inputBirthDayFormValidator(message, tempValue),
  };

  type FormFields = {
    birthDay: string;
    otp: string;
  };

  const setup: ValidatorSetup<FormFields> = {
    birthDay: {
      required: true,
      defaultValue: '',
      custom: condRequired.birthDay('生年月日を入力してください。'),
      maxLength: 8,
      sanitize: 'half width',
    },
    otp: {
      required: false,
      defaultValue: '',
    },
  };
  const { fields, values, isValid } = useFormValidator(setup);

  return (
    <BirthdayCheckForm fields={fields} isValid={isValid} values={values} />
  );
};

export default BirthdayCheckPage;
