/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Field } from '@alj-react/efo';
import EfoBirthDayField from '../UiComponents/BirthDayField/EfoBirthDayField';
import FormPageWrap from '../UiComponents/FormPageWrap';
import {
  FormSection,
  FormSectionTitle,
  FormSectionHeading,
  FormSectionContent,
  FieldExample,
} from '../UiComponents/TableForm';
import '../../App.scss';
import CheckNgModal from '../CheckNgModal/CheckNgModal';
import AxaButton from '../UiComponents/AxaButton/AxaButton';
import './BirthdayCheckForm.scss';

const URL_API = process.env.REACT_APP_URL_API || 'http://localhost:4000';

const getTitleApiUrl = `${URL_API}/birthdayCheck`;
const checkBirthdayApiUrl = `${URL_API}/birthdayCheck/auth`;

const paramErrorMessage = 'エラーが発生しました。(2)';
const commonErrorMessage = 'エラーが発生しました。';
const authErrorMessage =
  '生年月日の認証に失敗したため、このページは利用できません。';
const wrongSubmissionMessage = '入力された生年月日が正しくありません。';

type Props = {
  fields: {
    birthDay: Field<string>;
    otp: Field<string>;
  };
  isValid: any;
  values: any;
};

type errorBodyType = {
  result: boolean;
  errorCode: string;
  errorMessage: string;
  remainingAttempts: number;
  screenTitle: string;
  explanationText: string;
};

const BirthdayCheckForm = (props: Props) => {
  const getLocation = useLocation();
  const paramStr = getLocation.search.substr(1);
  const parseStr = qs.parse(paramStr);

  const { fields, values, isValid } = props;

  const [mainScreenTitle, setMainScreenTitle] = useState('');
  const [detailsText, setDetailsText] = useState('');
  const [birthText, setBirthText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [otpExplanation, setOtpExplanation] = useState('');
  const [otpTitle, setOtpTitle] = useState('');
  const [needOtp, setNeedOtp] = useState('');

  const [hideForm, setHideForm] = useState(true);
  const [formLocked, setFormLocked] = useState(false);

  const [modalShow, setShowModal] = useState(false);

  // Display error modal
  const showPopup = () => {
    setShowModal(true);
  };

  // Clode the error modal
  const closePopup = () => {
    setShowModal(false);
  };

  const getWrongSubmissionMessage = () => {
    if (needOtp) {
      return `入力された${birthText}もしくは${otpTitle}が正しくありません。`;
    }
    return `入力された${birthText}が正しくありません。`;
  };

  // Error message handling when user access the page (before form submit)
  const displayError = (data: errorBodyType) => {
    if (['E403'].includes(data.errorCode)) {
      // Too many submissions
      const { screenTitle, explanationText } = data;
      setMainScreenTitle(screenTitle);
      setDetailsText(explanationText);
      setErrorMessage(authErrorMessage);
    } else if (['E401', 'E405'].includes(data.errorCode)) {
      // Wrong parameters given - should not happen
      setErrorMessage(paramErrorMessage);
    } else {
      // For all other errors
      setErrorMessage(commonErrorMessage);
    }
    setHideForm(true);
  };

  // Error message handling when user submit the form
  const displayErrorSubmit = (data: errorBodyType) => {
    if (
      data.errorCode === 'E403' ||
      (data.errorCode === 'E404' && data.remainingAttempts === 0)
    ) {
      // If limit of submission reached
      setErrorMessage(authErrorMessage);
      setHideForm(true);
    } else if (data.errorCode === 'E404') {
      // If wrong submissions, but with some "retries"
      if (data.remainingAttempts === undefined) {
        // The Pattern below was removed from the requirements (remainingAttempts is now mandatory).
        // Better to keep it just in case..
        setErrorMessage(wrongSubmissionMessage);
      } else {
        setErrorMessage(
          `${getWrongSubmissionMessage()}あと${
            data.remainingAttempts
          }回入力に失敗すると、このページは使用できなくなります。`,
        );
      }
      showPopup();
    } else if (['E401', 'E402', 'E405'].includes(data.errorCode)) {
      // Wrong parameters given - should not happen
      // Form need to be hidden
      setErrorMessage(paramErrorMessage);
      setHideForm(true);
    } else {
      setErrorMessage(commonErrorMessage);
      setHideForm(true);
    }
  };

  useEffect(() => {
    axios
      .get(getTitleApiUrl, {
        params: {
          param: parseStr.param,
        },
      })
      .then((res) => {
        const {
          screenTitle,
          explanationText,
          birthdayText,
          otpText,
          otpExplanationText,
          hasOtp,
        } = res.data;
        setMainScreenTitle(screenTitle);
        setDetailsText(explanationText);
        setBirthText(birthdayText || 'ご契約者様の生年月日');
        setOtpExplanation(otpExplanationText || '');
        setOtpTitle(otpText || 'ワンタイムパスワード');
        setNeedOtp(hasOtp || false);

        //Display the form
        setHideForm(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          displayError(error.response.data);
        } else {
          // For the non-standart errors, we just displayed a basic error message
          setErrorMessage(commonErrorMessage);
          setHideForm(true);
        }
      });
  }, [parseStr.param, mainScreenTitle, detailsText]);

  const submitForm = () => {
    //Just to avoid double click
    if (formLocked) {
      return;
    }
    setFormLocked(true);

    const paramList = {
      param: parseStr.param,
      inputBirthday: values.birthDay,
      inputOtp: values.otp,
    };
    if (!values.otp) {
      delete paramList.inputOtp;
    }

    axios
      .get(checkBirthdayApiUrl, {
        params: paramList,
      })
      .then((res) => {
        setFormLocked(false);
        window.location.href = res.data.nextUrl;
      })
      .catch((error) => {
        setFormLocked(false);
        displayErrorSubmit(error.response.data);
      });
  };

  return !hideForm ? (
    <>
      <div className="container flex-column">
        <FormPageWrap>
          <FormSectionTitle>{mainScreenTitle}</FormSectionTitle>
          <FormSection>
            <FieldExample>
              {birthText}（西暦）を半角数字8ケタで入力してください。
              <br />
              （例：1971年6月5日→19710605）
              {otpExplanation && (
                <>
                  <br />
                  <p>{otpExplanation}</p>
                </>
              )}
              <br />
              <br />
              認証が成功しますと、お手続きのご案内画面に遷移します。
              <br />
              <br />
            </FieldExample>
            <FormSectionHeading>{birthText}</FormSectionHeading>
            <FormSectionContent>
              <EfoBirthDayField field={fields.birthDay} />
            </FormSectionContent>

            {needOtp && (
              <>
                <FormSectionHeading>{otpTitle}</FormSectionHeading>
                <FormSectionContent>
                  <EfoBirthDayField field={fields.otp} />
                </FormSectionContent>
              </>
            )}
          </FormSection>
        </FormPageWrap>
      </div>
      <div className="pagefooter">
        <div className="container">
          <div className="btn-toolbar ma-auto">
            <AxaButton disabled={!isValid} submitHandler={submitForm}>
              認証➡︎
            </AxaButton>
          </div>
        </div>
      </div>
      <div className="container flex-column">
        <FormPageWrap>
          <FieldExample>{detailsText}</FieldExample>
        </FormPageWrap>
      </div>
      <CheckNgModal
        show={modalShow}
        onHide={() => closePopup()}
        message={errorMessage}
      />
    </>
  ) : (
    <>
      <FormSectionTitle>{mainScreenTitle}</FormSectionTitle>
      <div className="container flex-column" style={{ textAlign: 'center' }}>
        {errorMessage}
        <br />
        {detailsText}
      </div>
    </>
  );
};
export default BirthdayCheckForm;
