// @flow strict
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import './CheckNgModal.scss';

const CheckNgModal = (props: any) => {
  const { onHide, message } = props;
  return (
    <div>
      <Modal
        {...props}
        className="check-ng-modal"
        dialogClassName="modal-dialog-centered"
        animation={false}
      >
        <Modal.Body>
          <p className="text-center">
            {message || '入力された生年月日が正しくありません。'}
          </p>
          <div className="confirmation-buttons">
            <Button bsStyle="default" onClick={onHide}>
              閉じる
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CheckNgModal;
