/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow strict
import React from 'react';

import './AxaButton.scss';

type Props = {
  children?: any;
  disabled?: boolean;
  type?: string;
  submitHandler?: Function;
  className?: string;
  btnSize?: string;
  btnType?: string;
};

const AxaButton = (props: Props) => {
  const {
    type,
    className,
    disabled,
    children,
    btnSize,
    btnType,
    submitHandler,
  } = props;
  const axaButtonType = 'AxaButton3';
  const classNameForButton = type
    ? `${axaButtonType} ${type} ${className || ''}`
    : `${axaButtonType} ${className || ''}`;

  if (disabled) {
    return (
      <a
        className={`${classNameForButton} disabled`}
        onClick={(e) => {
          e.preventDefault();
        }}
        onKeyDown={(e) => e.preventDefault()}
        role="link"
        tabIndex={0}
      >
        {children}
      </a>
    );
  }

  const sizeClass = btnSize === 'small' ? 'small' : '';
  const cssClass =
    btnType === 'backOrCancel' ? 'AxaButton2 ' : classNameForButton;

  return (
    <a
      className={`${cssClass}${sizeClass}`}
      onClick={(e) => {
        e.preventDefault();
        if (submitHandler) {
          submitHandler();
        }
      }}
      onKeyDown={(e) => e.preventDefault()}
      role="link"
      tabIndex={0}
    >
      {children}
    </a>
  );
};

export default AxaButton;
