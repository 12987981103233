import React from 'react';
import axalogo from '../../assets/image/axa_logo.png';
import './Header.scss';

const HeaderCustom = () => (
  <div className="Header">
    <div className="header-contents">
      <img className="logo" src={axalogo} alt="アクサ生命" />
    </div>
  </div>
);

export default HeaderCustom;
