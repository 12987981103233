import styled, { css } from 'styled-components';
import breakpoints from '../../styles/breakpoints';
import colors from '../../styles/colors';

export const TableForm = styled.div`
  line-height: 1.5;
  ${breakpoints.mediumUp(css`
    border-bottom: none;
    border: 1px solid ${colors.silver};
    display: table;
    margin-top: 10px;
    width: 100%;
  `)}
  ${breakpoints.smallOnly(css`
    margin-top: 30px;
  `)};
`;
export const TableFormSmall = styled.div`
  line-height: 1.5;
  margin-top: 30px;
`;

export const FormSection = styled.div`
  ${breakpoints.mediumUp(css`
    // border-bottom: 1px solid ${colors.silver};
  `)}
  & + & {
    ${breakpoints.smallOnly(css`
      margin-top: 50px;
    `)};
  }
`;

export const FormSectionTitle = styled.div`
  font-size: 18px;
  padding-bottom: 12px;
  text-align: center;
  border-bottom-style: solid;
  border-color: #00008f;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
`;

export const FormSectionHeading = styled.div`
  font-size: 16px;
  text-align: center;
  ${breakpoints.mediumUp(css`
    background-color: ${colors.white};
    display: block;
    min-width: 330px;
  `)};
`;

export const FormSectionHeadingSmall = styled.div`
  font-size: 14px;
  border-left: 4px solid ${colors.mercury};
  padding-left: 12px;
  margin-bottom: 22px;
`;
export const FormSectionContent = styled.div`
  ${breakpoints.mediumUp(css`
    text-align: center;
    display: inline-block;
    padding: 0 14px 14px 14px;
    width: 100%;
    & > *:first-child {
      margin-top: 0;
    }
  `)}
`;
export const FormSectionContentSmall = styled.div``;

export const ConfirmationTable = styled.div`
  line-height: 1.5;
  ${breakpoints.mediumUp(css`
    border: 1px solid ${colors.silver};
    border-bottom: none;
    display: table;
    width: 100%;
    margin-top: 10px;
  `)}
  ${breakpoints.smallOnly(css`
    background-color: ${colors.wildSand};
    font-size: 15px;
    padding: 22px;
    margin-top: 30px;
  `)};
`;

export const ConfirmationSection = styled.div`
  ${breakpoints.mediumUp(css`
    border-bottom: 1px solid ${colors.silver};
  `)}
  &:not(:last-child) {
    ${breakpoints.smallOnly(css`
      padding-bottom: 25px;
    `)};
  }
  & + & {
    ${breakpoints.smallOnly(css`
      padding-top: 25px;
      border-top: 1px solid #cbcbcb;
    `)};
  }
`;

export const ConfirmationSectionHeading = styled.div`
  font-weight: bold;
  ${breakpoints.mediumUp(css`
    background-color: ${colors.wildSand};
    border-right: 1px solid ${colors.silver};
    display: table-cell;
    font-size: 20px;
    padding: 14px;
    width: 330px;
    min-width: 330px;
    vertical-align: top;
  `)}
  ${breakpoints.smallOnly(css`
    font-size: 16px;
    margin-bottom: 20px;
  `)};
`;

export const ConfirmationSectionContent = styled.div`
  ${breakpoints.mediumUp(css`
    background-color: ${colors.alabaster};
    display: table-cell;
    padding: 14px;
    width: 100%;
    max-width: 870px;
    & > *:first-child {
      margin-top: 0;
    }
  `)}
`;

/*
 * FieldHint
 * for use in FormSectionContent
 * examples: '残り4000文字' or '※ハイフン(-)無しでご入力ください。'
 */
export const FieldHint = styled.p`
  color: ${colors.mediumGray};
  margin-bottom: 10px;
  font-size: 12px;
  ${breakpoints.mediumUp(css`
    margin-top: 10px;
  `)};
  ${breakpoints.smallOnly(css`
    margin-top: 12px;
  `)};
`;

/*
 * FieldLabel
 * for use in FormSectionContent
 * use as label for field when there are multiple fields inside FormSectionContent
 */
export const FieldLabel = styled.p`
  font-weight: bold;
  margin-bottom: 8px;
  ${breakpoints.mediumUp(css`
    font-size: 18px;
    margin-top: 25px;
  `)};
  ${breakpoints.smallOnly(css`
    font-size: 16px;
    margin-top: 30px;
  `)};
`;

/*
 * ConfirmationFieldLabel
 * for use in ConfirmationSectionContent
 * use as label for field when there are multiple fields inside ConfirmationSectionContent
 */
export const ConfirmationFieldLabel = styled.p`
  font-weight: bold;
  margin-top: 20px;
  ${breakpoints.mediumUp(css`
    font-size: 18px;
  `)};
  ${breakpoints.smallOnly(css`
    font-size: 15px;
  `)};
`;

/*
 * ConfirmationFieldValue
 * for use in ConfirmationSectionContent
 * use as label for field when there are multiple fields inside ConfirmationSectionContent
 */
export const ConfirmationFieldValue = styled.p`
  margin-top: 15px;
  ${breakpoints.mediumUp(css`
    font-size: 16px;
  `)};
  ${breakpoints.smallOnly(css`
    font-size: 14px;
  `)};
`;

/*
 * FieldExample
 * for use in FormSectionContent
 * example use case: '例）08098760000'
 */
export const FieldExample = styled.p`
  ${breakpoints.largeOnly(css`
    font-size: 16px;
    margin: 0 20%;
  `)};
  ${breakpoints.mediumUp(css`
    font-size: 16px;
  `)};
  ${breakpoints.smallOnly(css`
    font-size: 14px;
  `)};
`;

/*
 * FieldComment
 * for use in FormSectionContent
 * use to wrap random text
 */
export const FieldComment = styled.div`
  margin-bottom: 20px;
  ${breakpoints.mediumUp(css`
    font-size: 16px;
    margin-top: 25px;
  `)};
  ${breakpoints.smallOnly(css`
    font-size: 14px;
    margin-top: 20px;
  `)};
`;
