import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Header from './components/Header/Header';
import NoRouteMatch from './components/NoRouteMatch';
import BirthdayCheckPage from './components/BirthdayCheck/BirthdayCheckPage';

const App = () => (
  <Router>
    <Header />
    <div className="App">
      <Switch>
        <Route path="/birthday-check" exact component={BirthdayCheckPage} />
        <Route component={NoRouteMatch} />
      </Switch>
    </div>
  </Router>
);

export default App;
