import styled, { css } from 'styled-components';
import breakpoints from '../../styles/breakpoints';

export default styled.div`
  ${breakpoints.mediumUp(css`
    box-sizing: border-box;
    height: 80%;
    width: 80%;
    margin: 0 auto;
  `)}
  ${breakpoints.smallOnly(css`
    box-sizing: border-box;
    height: 80%;
    width: 80%;
    margin: 0 auto;
  `)};
`;
